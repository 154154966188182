  /* Define variables */
  :root {
    --primary-blue: #263B9B;
    --secondry-gold: #E1A854;
    --secondry-gold-hover: #efbe74;
    --secondry-gold-press: #ae803c;
    --accent-pink: #B34F81;
    --background-violet: #452F41;
    --background-offwhite: #F6F0F3;
    --dark-text: #3a3a3a;
  }

  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
  }

  .awake-btn {
    background-color: var(--secondry-gold);
    color: white;
    border-radius: 50px;
    padding: 7px 20px;
    border: none;
    outline: none;
    transition: background-color 0.1s;
  }

  .awake-btn:hover {
    background-color: var(--secondry-gold-hover) !important;
    transition: background-color 0.2s;
  }

  .awake-btn:active, 
  .awake-btn:focus:active {
    background-color: var(--secondry-gold-press);
    transition: background-color 0.05s;
  }

  @media (min-width: 768px) {
    .awake-btn:hover {
      background-color: var(--secondry-gold);
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    background-color: var(--background-offwhite);
    padding-top: 30px;
  }

  .section-title {
    font-size: 1.1em;
    font-weight: 600;
    text-align: center;
    margin: 5px 0px;
    padding: 5px 20px;
    border-radius: 10px;
    background-color: var(--accent-pink);
    margin-bottom: 10px;
    color: white;
  }

  .section-title-blue {
    font-size: 1.1em;
    font-weight: 600;
    text-align: center;
    margin: 5px 0px;
    padding: 5px 20px;
    border-radius: 10px;
    background-color: var(--primary-blue);
    margin-bottom: 10px;
    color: white;
  }

  .section-information-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
  }

  .section-information {
    padding: 0px 10px;
    font-size: 0.8em;
    color: #545454;
  }

  .section-details-btn {
    margin: 12px 0px;
  }

  .book-now-btn {
    width: 220px;
    height: 50px;
    font-size: 1.4em;
    font-weight: 600;
    margin-bottom: 30px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }

  .code-of-conduct-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Add this if you want vertical centering within the container */
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    background-color: white;
    padding: 20px 0px;
  }

  .code-of-conduct-information {
    text-align: center;
    width: 80%; 
    max-width: 600px;
    margin-bottom: 14px;
    font-weight: 300;
    font-size: 0.9em;
    color: #545454;
  }

  .schedule-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
    margin: 30px;
    padding-top: 10px;
    padding-bottom: 50px;
  }

  .schedule-image {
    max-width: 100%;
    height: auto;
    display: block; /* removes any extra whitespace below image */
    padding-top: 10px;
    margin: 0 auto; /* center the image if you like */
  }

  #workshopschedule .schedule-carousel .embla {
    --slide-size: 65%;
    --slide-height: calc(50rem * 1.3);
  }

  @media (min-width: 768px) {
    
    .book-now-btn {
      width: 330px;
      height: 75px;
      font-size: 1.8em;
      font-weight: 600;
      margin-bottom: 50px;
    }

    .section-title {
      font-size: 1.8em;
      font-weight: 600;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 5px 40px;
    }

    .section-information-container {
      margin-bottom: 20px;
    }

    .section-information {
      margin-top: 6px;
      font-size: 1.5em;
    }

    .section-details-btn {
      width: 220px;
      height: 50px;
      font-size: 1.2em;
      font-weight: 600;
      margin-top: 30px;
    }

    .code-of-conduct-container {
      padding-top: 0px;
    }

    .code-of-conduct-information {
      margin-top: 10px;
      margin-bottom: 14px;
      font-weight: 300;
      font-size: 1.3em;
      color: #545454;
    }

    .code-of-conduct-btn{
      width: 250px;
      height: 60px;
      font-size: 1.2em;
      font-weight: 600;
      margin-top: 30px;
    }

    .schedule-image {
      max-width: 90%; /* or whatever width works for your design */
    }
  }

  .video-container {
    overflow: hidden;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hotel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
    margin: 30px;
    padding-top: 10px;
  }

  .hotel-title {
    font-size: 1.3em;
    font-weight: 600;
    margin: 10px 0px;
  }

  .embla {
    --slide-spacing: 20px;
    --slide-size: 50%;
    --slide-height: 50rem;
    padding: 5px 0px 20px 0px;
  }

  .embla__viewport {
    max-width: 1920px;
    overflow: hidden;
  }
  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
  }
  .embla__slide__img {
    height: auto; /* This allows the image to determine its own height based on width */
    max-height: var(--slide-height); /* Set a max height so it doesn't grow indefinitely */
    width: 100%;
    object-fit: contain; /* Change to contain so the image won't get cropped */
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 26.5px;
    height: 22px;
    left: 20px;
    top: 20px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #BDC5DF;
    height: 10% !important;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    right: 15px;
    top: 15px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    background: var(--primary-blue);
    padding: 2.5em 1.5em 0;
    font-size: 1.3em;
    font-weight: 400;
    overflow: visible !important;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: var(--primary-blue);
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Styling of individual items */
  .menu-item {
    display: block;
    margin-bottom: 14px;
    text-decoration: none;
    transition: color 0.2s;
    color: white;
  }

  .selected-menu-item {
    color: var(--secondry-gold);
    font-size: 1.2em;
    font-weight: 500;
  }

  .menu-item:hover {
    color: var(--secondry-gold-hover);
  }

  .bm-item:hover {
    color: var(--secondry-gold-hover);
  }
