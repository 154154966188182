/* ========================================== GENERAL MODAL STYLES */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  position: relative;
  width: 90%;
  max-height: 90%;
  max-width: 600px;
  margin: auto; /* Center the modal content horizontally */
  overflow-y: auto; /* Enable vertical scrolling */
  box-sizing: border-box;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling of main page when the modal is open */
}

.modal-paragraph-with-image {
  display: flex;
  align-items: center;
}

.modal-content-spacer {
  width: 50px;
}

/* ========================================== INFORMATION MODAL STYLES */

.information-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.information-modal-content-title {
  width: 70%;
}

.information-modal-content-text {
  font-size: 0.9em;
  font-weight: 300;
  color: var(--dark-text);
}

.information-modal-content-image-full {
  width: 100%;
  object-fit: cover;
}

.information-modal-content-image-small {
  width: 50%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .information-modal-content {
    margin: 0px 20px;
  }

  .information-modal-content-text {
    font-size: 1.1em;
  }
}