.our-lineup {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 85%;
  margin: 30px 10px;
  background-color: white;
  border-radius: 30px;
  padding: 10px;
}
  
.our-lineup h2 {
  background-color: var(--accent-pink);
  color: white;
  padding: 5px 40px;
  width: 180px;
  text-align: center;
  margin: 15px;
  border-radius: 8px;
}
  
.artist-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 12px;
  background-color: var(--background-violet);
  border-radius: 15px;
  margin-bottom: 15px;
  width: 90%;
}
  
.profile-picture {
  height: 165px;
  width: 143px;
  object-fit: cover;
  border-radius: 12px;
}
  
.artist-info {
  margin-left: 10px;
  min-height: 161px;
  width: 160px;
  background-color: white;
  border-radius: 0px 0px 6px 6px;
  display: flex; /* Use flex layout */
  flex-direction: column; /* Stack children vertically */
  justify-content: space-between; /* Distribute space evenly */
}
  
.artist-name {
  display:flex;
  padding: 3px 5px;
  background-color: var(--accent-pink);
  color: white;
  font-size: 0.85em;
  font-weight: 400;
  justify-content: center;
}

.artist-info p {
  padding: 0px 5px;
  margin-top: 10px;
  font-size: 0.85em;
  font-weight: 200;
}

.artist-details-btn {
  align-self: center; /* Align button to the center of flex container */
  margin-bottom: 10px; /* Add some margin at the bottom if needed */
}

.artist-content {
  display: flex; /* Use flex layout */
  flex-direction: column; /* Stack children vertically */
}

.artist-content-name {
  display: flex;
  background-color: var(--accent-pink);
  align-self: center;
  width: 75%;
  padding:7px 20px;
  color: white;
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 10px;
  border-radius: 7px;
  justify-content: center;
}

.artist-details-container {
  background-color: var(--background-offwhite);;
  padding: 0px 7px;
  border-radius: 0px 0px 5px 5px;
}
  
@media (min-width: 768px) {
  .our-lineup {
    max-width: 700px;
    font-size: 1.2em;
  }

  .our-lineup h2 {
    font-size: 1.8em;
    width: 200px;
  }

  .artist-card {
    padding: 30px 20px;
    margin-bottom: 25px;
  }

  .profile-picture {
    height: 396px;
    width: 312px;
  }

  .artist-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
    height: 396px;
    width: 300px;
  }

  .artist-name {
    font-size: 1.3em;
  }

  .artist-info p {
    padding-left: 5%;
    margin-top: 15px;
    font-size: 1em;
  }

  .artist-details-btn {
    align-self: center; /* Align button to the center of flex container */
    width: 160px;
    height: 40px;
    font-size: 0.9em;
    font-weight: 600;
    margin-top: auto;
  }
}