:root{
  --background-offwhite: #F6F0F3;
}

.MainHeader {
    height: 300px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-offwhite);
  }

  .Background {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 0px 0px 50px 50px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .BackgroundImage {
    flex: 1;
    width: 33.33%;
    height: 100%;
  }

  .MainHeader h3 {
    align-self: flex-start;
    margin-left: 9%;
    font-family: Haettenschweiler;
    font-size: 2.6rem;
    font-weight: 200;
    color: #f0bc6f;
    margin-top: 1px;
    margin-left: 9%;
    z-index: 2;
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
  }
  
  .Logo {
    height: 33%;
    align-self: flex-start;
    margin-left: 9%;
    margin-top: 15px;
    z-index: 2;
  }

  .Artists {
    position: absolute;
    bottom: 0px;
    height: 90%;
    z-index: 2;
  }
  
  @media (max-width: 768px) {
    .Background {
      background: url('/public/headermobile.jpg') no-repeat center center/cover;
    }
    
    .BackgroundImage {
      display: none;
    }

    .Logo {
      align-self: center;
      margin-left: 0%;
      height: 22%;
    }

    .MainHeader h3 {
      align-self: center;
      margin-left: 0%;
      font-size: 1.5rem;
    }

    .Artists {
      height: 60%;
    }
  }
  
  @media (min-width: 768px) {
    .Logo {
      width: auto;      /* This will respect the original image width */
      max-width: 250px; /* You can adjust this value to your desired maximum width */
    }
  }
  