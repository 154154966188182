.popup-content {
  background-color: #4D4D4D;
  border: 4px solid #CFCFCF;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 80%;
  margin: 0 auto;
  position: relative;
}
  
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h2 {
  margin: 0;
  text-align: center;
  flex: 1;
  margin-bottom: 15px;
}

.popup-form {
  display: flex;
  flex-direction: column;
}
  
input[type="text"], input[type="email"], textarea {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 15px;
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

input:focus, textarea:focus {
  outline: none;
  background-color: #f6eaea;
}
  
.name-fields {
  display: flex;
  justify-content: space-between;
  gap: 4%;
}

textarea {
  height: 160px;
  border-radius: 15px;
}

.popup-form .submit-button {
  padding: 10px 30px;
  font-size: 16px;
  align-self: flex-end;
}
  