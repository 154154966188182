.rottnest-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    padding: 15px 0px;
    background-color: white;
}
  
.rottnest-picture {
    width: 100%;
    height: auto;
    object-fit: cover;
}
   
.rottnest-information-disclaimer {
  margin-top: 6px;
  padding: 0px 30px;
  font-size: 0.8em;
  font-style: italic;
  font-weight: 500;
  color: #545454;
}
  
.rottnest-date {
    font-size: 1.3em;
    font-weight: 600;
    color: var(--secondry-gold);
}
  
.rottnest-options {
    font-size: 1.1em;
    font-weight: 600;
    color: var(--background-violet);
    margin-bottom: 20px;
}

@media (min-width: 768px) {
  .rottnest-picture {
    border-radius: 25px;
    width: 1200px;
    margin-top: 25px;
    margin-bottom: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }

  .rottnest-information-disclaimer {
    margin-top: 20px;
    font-size: 1.2em;
  }
}