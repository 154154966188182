.preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensures it appears on top */
    cursor: pointer;
  }
  
  .preview-image {
    width: 100%;
    height: auto;
    max-width: 95%;
  }