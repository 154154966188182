:root {
  --primary-blue: #263B9B;
}

.Footer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-blue);
  color: white;
  border-radius: 30px 30px 0px 0px;
  flex-direction: column;
}
  
@media (min-width: 768px) {
  .Footer > * {
    max-width: 600px;
    width: 100%;
  }
}
  
.subscribe-section {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
  
.subscribe-section p {
  font-size: 0.9em;
  font-weight: 200;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 3px;
}
  
.input-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  flex-direction: column;
}
  
.subscribe-input{
  border-radius: 50px;
  padding: 7px 20px;
  border: none;
  outline: none;
}
  
.subscribe-input {
  flex-grow: 1;  /* Allows input to expand */
}

.name-input-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 2%; /* Or whatever gap you prefer between the name inputs */
}


.name-input {
  width: 38%; /* Assuming a bit of gap is needed between two inputs */
}
  
.subscribe-btn {
  align-self: flex-end;
  display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 768px) {
  .subscribe-btn {
    width: 160px;
    height: 35px;
    font-size: 1.1em;
  }
}

.footer-line {
  width: 80%;
  margin: 7px 0; 
  border: none;
  border-top: 1px solid white;
}

.social-media-section {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px; /* Provides vertical spacing between items */
  margin: 8px 0px;
}

.social-btn {
  background: transparent;
  cursor: pointer;
}

.social-btn img {
  display: block;
  width: 35px;
  height: 35px;
}

.social-media {
  display: flex;
  gap: 10px;
}

.copyright {
  font-size: 0.7em;
  font-weight: 100;
  text-align: center;
  margin: 20px 0px;
}

.contact-us-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contact-btn {
  width: 180px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .contact-btn {
    width: 200px;
    height: 35px;
    font-size: 1.2em;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-button {
  background: white;
  border-radius: 50%;
  border: 4px solid #4D4D4D;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 60px;
  height: 60px;
  font-size: 20px;
  color: #4D4D4D;
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%); 
}

@media (min-width: 768px) {
  .close-button {
    bottom: 50%;
    transform: translateX(-50%) translateY(300px);
  }
}
  